export const DOWNLOAD_WORKER_URL =     '"undefined" == typeof WebSocket && (global.WebSocket = require("isomorphic-ws"));\n' +
    '\n' +
    'const workerMain = function (e) {\n' +
    '  "use strict";\n' +
    '  const n = e.data["///ndt/v7/download"];\n' +
    '  const o = new WebSocket(n, "net.measurementlab.ndt.v7");\n' +
    '\n' +
    '  let t = () => new Date().getTime();\n' +
    '  if ("undefined" != typeof performance && void 0 !== performance.now) {\n' +
    '    t = () => performance.now();\n' +
    '  }\n' +
    '\n' +
    '  downloadTest(o, postMessage, t);\n' +
    '};\n' +
    '\n' +
    'const downloadTest = function (e, n, o) {\n' +
    '  e.onclose = function () {\n' +
    '    n({ MsgType: "complete" });\n' +
    '  };\n' +
    '\n' +
    '  e.onerror = function (e) {\n' +
    '    n({ MsgType: "error", Error: e });\n' +
    '  };\n' +
    '\n' +
    '  let t = o();\n' +
    '  let s = t;\n' +
    '  let a = 0;\n' +
    '\n' +
    '  e.onopen = function () {\n' +
    '    t = o();\n' +
    '    s = t;\n' +
    '    a = 0;\n' +
    '    n({ MsgType: "start", Data: { ClientStartTime: t } });\n' +
    '  };\n' +
    '\n' +
    '  e.onmessage = function (e) {\n' +
    '    a += void 0 !== e.data.size ? e.data.size : e.data.length;\n' +
    '    const r = o();\n' +
    '    if (r - s > 250) {\n' +
    '      n({\n' +
    '        MsgType: "measurement",\n' +
    '        ClientData: {\n' +
    '          ElapsedTime: (r - t) / 1e3,\n' +
    '          NumBytes: a,\n' +
    '          MeanClientMbps: (a / (r - t)) * 0.008,\n' +
    '        },\n' +
    '        Source: "client",\n' +
    '      });\n' +
    '      s = r;\n' +
    '    }\n' +
    '    if (typeof e.data === "string") {\n' +
    '      n({ MsgType: "measurement", ServerMessage: e.data, Source: "server" });\n' +
    '    }\n' +
    '  };\n' +
    '};\n' +
    '\n' +
    'function handleError(error) {\n' +
    '  // Handle the error here\n' +
    '  console.error("WebSocket error:", error);\n' +
    '  n({\n' +
    '          MsgType: "error-ws",\n' +
    '          Error: error\n' +
    '        });\n' +
    '}' +
    'if ("undefined" != typeof self) {\n' +
    '  self.onmessage = workerMain;\n' +
    '} else if (void 0 !== this) {\n' +
    '  this.onmessage = workerMain;\n' +
    '} else if ("undefined" != typeof onmessage) {\n' +
    '  onmessage = workerMain;\n' +
    '}\n'
