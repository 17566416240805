import { createSlice } from '@reduxjs/toolkit';

export const serverSelectedSlice = createSlice({
  name: 'serverSelected',
  initialState: {
    value: null,
  },
  reducers: {
    setServerSelected: (state,action) => {
      state.value = action.payload;
    },
  },
});

export const { setServerSelected } =
    serverSelectedSlice.actions;

export default serverSelectedSlice.reducer;
