import React, { useEffect, useState } from 'react';
import speedTestStyle from '../../styles/speedTestStyle.module.css';
import {MdArrowDownward, MdArrowUpward} from "react-icons/md";

const AnimatedNumber = props => {
    const [fontSize, setFontSize] = useState(150); // Initial font size

    useEffect(() => {
        const handleResize = () => {
            // Adjust font size based on screen width
            if (window.innerWidth <= 768) {
                setFontSize(150);
            } else if (window.innerWidth <= 1024) {
                setFontSize(170);
            } else {
                setFontSize(200);
            }
        };

        handleResize(); // Set initial font size based on screen width

        // Event listener for window resize
        window.addEventListener('resize', handleResize);

        return () => {
            // Clean up the event listener
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const containerStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 16,
    };

    const bigTextStyle = {
        fontSize: "clamp(2rem, 25vw, 15rem)",
    };

    const smallTextStyle = {
        fontSize: 20,
    };

    return (
        <div style={containerStyle}>
            <span className={speedTestStyle.animatedNumber} style={bigTextStyle}>{props.value.toFixed(1)}</span>
            <span className={speedTestStyle.animatedNumber} style={smallTextStyle}>mbps            {props.isUploading ? <MdArrowUpward/>: <MdArrowDownward />}
</span>

        </div>
    );
};

export default AnimatedNumber;
