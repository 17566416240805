import '../App.css';
import {React, useEffect, useState} from 'react';
import SpeedTest from '../containers/SpeedTest';
import speedTestStyle from '../styles/speedTestStyle.module.css';
import '../styles/speedTestStyle.module.css';
import {
    MdArrowUpward,
    MdArrowDownward,
    MdOutlineStorage,
    MdDomain,
    MdOutlineSwapVert,
    MdChangeCircle
} from 'react-icons/md';
import AnimatedNumber from "./text/AnimatedNumber";
import {Accordion, Button, Col, Container, Row, Toast} from "react-bootstrap";
import {TbTilde} from "react-icons/tb";
import ValuesBox from "./box/valuesBox";
import styled, {keyframes} from 'styled-components';
import Dropdown from 'react-bootstrap/Dropdown';
import {setServerSelected} from "../stores/reducers/serverSelectedSlice";
import {useDispatch} from "react-redux";


let downloadDataPlot = [];
let uploadDataPlot = []

// Define the fade animation keyframes
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
// Styled component for the animated ValuesBox
export const AnimatedValuesBox = styled.div`
  animation: ${fadeIn} 800ms ease-in;
`;


const Home = () => {
    const [downloadData, setDownloadData] = useState(0);
    const [uploadData, setUploadData] = useState(0);
    const [serverInfo, setServerInfo] = useState([]);
    // const [historyData, setHistoryData] = useState(
    //     [
    //         {
    //             "upload": "9.96",
    //             "timestamp": "8:27",
    //             "jitter": "2.06ms",
    //             "location": " Milan,IT (mlab1-mil06)",
    //             "latency": "133ms",
    //             "download": "40.07"
    //         },
    //         {
    //             "upload": "11.16",
    //             "timestamp": "8:27",
    //             "jitter": "7.81ms",
    //             "location": " Milan,IT (mlab1-mil05)",
    //             "latency": "156ms",
    //             "download": "33.78"
    //         }
    //     ]
    // );
    const [historyData, setHistoryData] = useState([])

    const [serverList, setServerList] = useState([]);
    const [currentDownload, setCurrentDownload] = useState('');
    const [currentLatency, setCurrentLatency] = useState();
    const [currentLocation, setCurrentLocation] = useState();
    const [currentJitter, setCurrentJitter] = useState();
    const [isUploading, setIsUploading] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [isProcessFinished, setIsProcessFinished] = useState(false);
    const [isToastVisible, setIsToastVisible] = useState(false);
    const [isChangeServerVisible, setIsChangeServerVisible] = useState(true);

    const dispatch = useDispatch()


    /*******This part manage the reset of plot data everyTime the button is clicked
     * Initial State -> (onButtonClick or OnPageRefresh) isProcessFinished = undefined
     * during Download -> isProcessFinished = false
     * at the end of upload isProcessFinished = true* */
    if (isProcessFinished === undefined) {
        downloadDataPlot = [0];
        uploadDataPlot = [0];
    }
    /******************************* */

    const handleDownloadData = (dwnl) => {
        setDownloadData(dwnl);// Data directly sent to Gauge
        downloadDataPlot.push(dwnl);//Data to create array to be pushed on Plot
    }
    const handleUploadData = (upld) => {
        setUploadData(upld);// Data directly sent to Gauge
        uploadDataPlot.push(upld);//Data to create array to be pushed on Plot
    }
    const handleServerInfo = (srvInfo) => {
        setServerInfo(srvInfo);// Location and Nation state
    }
    //State Variables to manage in which state the FSM is
    const isDownloadingFunction = (isDwnld) => {
        setIsDownloading(isDwnld);
    }
    const isUploadingFunction = (isUpld) => {
        setIsUploading(isUpld);
    }
    /////////////////////////////////////////////////////

    //State Variable to manage if the Test is finished (it's true only when everything is printed on screen, and the test function has finished)
    const processFinished = (prcsFinished) => {
        setIsProcessFinished(prcsFinished);
    }

    useEffect(() => {
        if (isProcessFinished) {
            setHistoryData(prevState => {
                const updatedData = [...prevState];
                const lastItemIndex = updatedData.length - 1;
                updatedData[lastItemIndex].jitter = currentJitter;
                updatedData[lastItemIndex].location = currentLocation;
                updatedData[lastItemIndex].latency = currentLatency;
                updatedData[lastItemIndex].download = currentDownload;
                return updatedData;
            });
            setUploadData(0)
            setDownloadData(0)
            resetInformationField()
        }

    }, [isProcessFinished])

    const onDownloadComplete = (value) => {
        setCurrentDownload(value);


    };

    const onUploadComplete = (value) => {
        const currentDate = new Date();
        const formattedDate = `${currentDate.getHours()}:${currentDate.getMinutes()}`;
        setHistoryData(prevState => [...prevState,
            {upload: value, timestamp: formattedDate}]);
        console.log(historyData)
    };

    const onShowToast = () => {
        setIsToastVisible(true);

        setTimeout(() => {
            setIsToastVisible(false);
        }, 3000);
    };

    const resetInformationField = () => {
        // document.getElementById('locationText').textContent = ' ';
        // document.getElementById('ipText').textContent = ' ';
        document.getElementById('latencyText').textContent = ' ';
        document.getElementById('jitterText').textContent = ' ';
        document.getElementById('downloadText').textContent = ' ';
        document.getElementById('uploadText').textContent = ' ';
        setDownloadData(0)
        setUploadData(0)
    }
    const handleServerList = (serverList) => {
        console.log("Server list: ", serverList)
        setServerList(serverList)
        dispatch(setServerSelected(serverList[0]))
        document.getElementById('locationText').textContent = ' ' + serverList[0].location.city + ',' + serverList[0].location.country + ` (${serverList[0].machine.split('.')[0]})`;
    }

    const handleLocation = (loc) => {
        setCurrentLocation(loc)
    }

    const handleLatency = (lat) => {
        setCurrentLatency(lat)
    }
    const handleJitter = (jit) => {
        setCurrentJitter(jit)
    }

    const onProcessStart = (bool) => {
        setIsChangeServerVisible(!bool)
    }


    return (

        <div id="home">
            <Container className='mt-4 align-items-center'>
                <Row>
                    {historyData.length > 1 &&
                        <Accordion className={`mb-3 ${speedTestStyle.accordion}`} style={{border: 'none'}}>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <button
                                        className="accordion-button d-block text-center "
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne"
                                        aria-expanded="true"
                                        aria-controls="collapseOne"
                                     >
                                     <p style={{fontSize: 'large'}}>History</p>
                                    </button>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row>
                                        {historyData.slice(0, -1).map((item, index) => (
                                            <Col key={index}
                                            >
                                                {index === historyData.length - 1 ? (
                                                    <AnimatedValuesBox>
                                                        <ValuesBox
                                                            leftValue={item.download}
                                                            rightValue={item.upload}
                                                            timestamp={item.timestamp}
                                                            jitter={item.jitter}
                                                            location={item.location}
                                                            latency={item.latency}
                                                        />
                                                    </AnimatedValuesBox>
                                                ) : (
                                                    <ValuesBox
                                                        leftValue={item.download}
                                                        rightValue={item.upload}
                                                        timestamp={item.timestamp}
                                                        jitter={item.jitter}
                                                        location={item.location}
                                                        latency={item.latency}
                                                    />
                                                )}
                                            </Col>
                                        ))}
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>}
                    {/*ONLY LAST ITEM*/}
                    {historyData.length > 0 && <Col>
                        <AnimatedValuesBox>
                            <ValuesBox
                                leftValue={historyData[historyData.length - 1].download}
                                rightValue={historyData[historyData.length - 1].upload}
                                timestamp={historyData[historyData.length - 1].timestamp}
                                jitter={historyData[historyData.length - 1].jitter}
                                location={historyData[historyData.length - 1].location}
                                latency={historyData[historyData.length - 1].latency}
                            />
                        </AnimatedValuesBox>
                    </Col>}
                </Row>
                <Row>
                    <Col>
                        {isUploading ? (
                            <AnimatedNumber isUploading={isUploading} value={uploadData}/>
                        ) : (
                            <AnimatedNumber isUploading={isUploading} value={downloadData}/>
                        )}
                    </Col>
                </Row>

                <Row>
                    <Col style={{textAlign: 'center', paddingBottom: 20}}>
                        <SpeedTest
                            onDownloadComplete={onDownloadComplete}
                            onUploadComplete={onUploadComplete}
                            handleDownloadData={handleDownloadData}
                            handleUploadData={handleUploadData}
                            handleServerInfo={handleServerInfo}
                            isDownloadingFunction={isDownloadingFunction}
                            isUploadingFunction={isUploadingFunction}
                            processFinished={processFinished}
                            handleServerList={handleServerList}
                            handleLocation={handleLocation}
                            handleJitter={handleJitter}
                            handleLatency={handleLatency}
                            resetInformationField={resetInformationField}
                            onServerRefresh={onShowToast}
                            onProcessStart={onProcessStart}
                        />
                    </Col>
                </Row>
                <Row style={{ textAlign: "-webkit-center" }}>
                    <Col>
                        {isToastVisible && (
                            <div className={`${speedTestStyle.toastOverlay}`}>
                                <Toast className={`${speedTestStyle.toast}`} show={isToastVisible}
                                       style={{
                                           position: 'absolute',
                                           top: '50%',
                                           left: '50%',
                                           transform: 'translate(-50%, -50%)',
                                           zIndex: 9999,
                                       }}>
                                    <Toast.Body >Server refresh</Toast.Body>
                                </Toast>
                            </div>
                        )}
                    </Col>
                </Row>
            </Container>
            <Container className='full-width-container speedData text-center' style={{textAlign: "-webkit-center"}}>
                <Row style={{textAlign: "-webkit-center"}}>
                    <Col className='col-12'>
                        <p id="location" className={`d-flex justify-content-center ${speedTestStyle.location}`}>
                            <Col className='col-10'>

                            <span>
                                <MdOutlineStorage/>
                                <span id="locationText" className={`text-center ${speedTestStyle.locationText}`}></span>
                            </span>
                            </Col>
                            {(serverList.length > 0 && isChangeServerVisible) && <Dropdown>
                                <Dropdown.Toggle variant="primary"
                                                 className={`${speedTestStyle.dropdownButton}`}><MdChangeCircle
                                    className={`${speedTestStyle.changeServerIcon}`}/></Dropdown.Toggle>
                                <Dropdown.Menu className={`${speedTestStyle.dropdownButton}`}>
                                    {serverList.map((item, index) => (
                                        <Dropdown.Item onClick={() => {
                                            dispatch(setServerSelected(item))
                                            document.getElementById('locationText').textContent = ' ' + item.location.city + ',' + item.location.country + ` (${item.machine.split('.')[0]})`;

                                        }} key={index}
                                                       className={`${speedTestStyle.dropdownItem}`}>{item.location?.city}-{item.location?.country} ({item.machine.split('.')[0]})</Dropdown.Item>

                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>}

                        </p>
                    </Col>
                    <Col className='col-12'>
                        <p id='ip' className={`d-flex justify-content-center ${speedTestStyle.ip}`}>
                            <MdDomain/>
                            <span id="ipText" className={`text-center ${speedTestStyle.ipText}`}></span>
                        </p>
                    </Col>
                </Row>
                <Row className='justify-content-center ' style={{textAlign: "-webkit-center"}}>
                    <Col xs={6}>
                        <p id='download' className={`d-flex justify-content-center ${speedTestStyle.download}`}>
                            <MdArrowDownward/>
                            <span id="downloadText" className={`text-center ${speedTestStyle.downloadText}`}></span>
                        </p>

                    </Col>
                    <Col xs={6}>
                        <p id="upload" className={`d-flex justify-content-center ${speedTestStyle.upload}`}>
                            <MdArrowUpward/>
                            <span id="uploadText" className={`text-center ${speedTestStyle.uploadText}`}></span>
                        </p>
                    </Col>
                </Row>
                <Row className='justify-content-center ' style={{textAlign: "-webkit-center"}}>
                    <Col xs={6}>
                        <p id="latency" className={`d-flex justify-content-center ${speedTestStyle.latency}`}>
                            <MdOutlineSwapVert/>
                            <span id="latencyText" className={`text-center ${speedTestStyle.latencyText}`}></span>
                        </p>

                    </Col>
                    <Col xs={6}>
                        <p id="jitter" className={`d-flex justify-content-center ${speedTestStyle.jitter}`}>
                            <TbTilde/>
                            <span id="jitterText" className={`text-center ${speedTestStyle.jitterText}`}></span>
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Home;
