

const Button = ({onClick, className, icon, title, hidden}) => {

    return (
        <button id="button" type='button' hidden={hidden} onClick={onClick} className={className} title={title}>{icon}</button>
    )
}

export default Button;
