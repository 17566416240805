import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from "i18next-http-backend"; // <---- add this

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
    en: {
      translation: {
        header : 'WeeNet  Speed Test',//Unused
        download: "Download Speed",
        upload: "Upload Speed",
        measurementFinish: 'Speed Test',//Unused
        measurementFinishLabel: 'Complete',

                /*RELATED TO THE PAGE ABOUT*/ 
        aboutTexth2: 'WeeNet - Network Tool is a complete network utility.',
        aboutText1: ' WeeNet allows you to do:',
        aboutLi1: 'Scan Wireless Networks nearby with the possibility to apply several filters 2. Graphically Identify all occupied channels from Wireless Networks nearby',
        aboutLi2: 'Scan all the devices connected to your local networks',
        aboutLi3: 'Obtain devices connected parameters in details (Hostname, MAC and IP), thru NetBIOS, UPnP & Bonjour',
        aboutLi4: 'Network Speedtest, to reveal your download and upload speeds',
        aboutLi5: 'Speedtest della rete per scoprire la velocità di download e upload',
        aboutLi6: 'Wake On Lan',
        aboutLi7: 'Ping',
        aboutLi8: 'TraceRoute',
        aboutLi9: 'Ports Scan',
        aboutLi10: 'WHOIS',
        aboutLi11: 'DNS Lookup',
        aboutLi12: 'Custom Subnet Scan',
        aboutLi13: 'VPN hosts scan',
        aboutLi14: 'Know more about your device in your LAN',
        aboutLi15: 'Diagnosis of your wireless Access Point identifying security flaws and interference',
        aboutText2: 'Which questions WeeNet reply?',
        aboutLi16: 'Does anyone steal my Wi-Fi?',
        aboutLi17: 'Is my network safe?',
        aboutLi18: 'Why my network is running slowly ?',
        aboutLi19: 'Why my wireless network is not reachable in my room?',
        aboutLi20: 'Does my internet provider give me the right network speed ?',
        aboutText3: 'and much more!',

        /*RELATED TO THE PAGE CONTACT*/ 
        contactText1: 'You can find us here:',
        contactText2: 'on our website',
        contactText3: 'or on social media'
        
      },
    },
    it: {
      translation: {
        header : 'WeeNet  Speed Test',//Unused
        download: "Velocità Download",
        upload: "Velocità Upload",
        measurementFinish: 'Speed Test',//Unused
        measurementFinishLabel: 'Completato',

        /*RELATED TO THE PAGE ABOUT*/ 
        aboutTexth2: 'WeeNet è uno strumento di rete completo!',
        aboutText1: ' WeeNet ti permette di:',
        aboutLi1: 'Effettuare lo scan delle reti wireless nelle vicinanza con la possibilità di applicare numerosi filtri',
        aboutLi2: 'Identificare graficamente tutti i canali occupati dalle reti wireless nelle vicinanze',
        aboutLi3: 'Effettuare lo scan dei dispositivi connessi alla tua rete locale',
        aboutLi4: 'Ottenere i parametri dei dispositivi connessi alla tua rete in dettaglio (HOSTNAME, MAC e IP), attraverso NetBios, UPnP e Bonjour',
        aboutLi5: 'Speedtest della rete per scoprire la velocità di download e upload',
        aboutLi6: 'Wake On Lan',
        aboutLi7: 'Ping',
        aboutLi8: 'TraceRoute',
        aboutLi9: 'Ports Scan',
        aboutLi10: 'WHOIS',
        aboutLi11: 'DNS Lookup',
        aboutLi12: 'Custom Subnet Scan',
        aboutLi13: 'VPN hosts scan',
        aboutLi14: 'Sapere di piu` riguardo i dispositivi connessi alla tua rete locale',
        aboutLi15: 'Diagnosi del tuo access point wifi identificando problematiche di sicurezza e intereferenze',
        aboutText2: 'A quali domande risponde WeeNet?',
        aboutLi16: 'Qualcuno ruba il mio Wi-Fi?',
        aboutLi17: 'La mia rete è sicura?',
        aboutLi18: 'Perchè la mia rete è così lenta?',
        aboutLi19: 'Perchè la rete Wi-Fi di casa non prende in camera mia?',
        aboutLi20: 'Il mio fornitore di Internet mi sta fornendo la giusta velocità di rete?',
        aboutText3: 'E molto altro ancora!',

                /*RELATED TO THE PAGE CONTACT*/ 
                contactText1: 'Puoi trovarci qui:',
                contactText2: 'sul nostro sito web:',
                contactText3: 'o sui social:'
      },
    },
  };

  const options = {
    order: ['path', 'navigator'],
    lookupQuerystring: 'lng'
  }

i18n
.use(XHR)
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    initImmediate: false,
    resources,
    detection: options,
    lng:'en',
    fallbackLng: 'en',

    supportedLngs: ["en", "it"],

    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

  });

  i18n.changeLanguage()


export default i18n;