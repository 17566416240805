import React, {useState, useEffect} from 'react';
import {Routes, Route, useLocation} from 'react-router-dom';
import Home from './components/Home';
import LoadingScreen from './components/loadingPage';
import {Provider} from "react-redux";
import store from './stores/store';

function App() {
    const [loading, setLoading] = useState(true);
    const [theme, setTheme] = useState('light');
    const location = useLocation();

    useEffect(() => {
        setTimeout(() => setLoading(false), 1500);
    }, []);

    useEffect(() => {
        const handleZoom = (event) => {
            if (event.touches.length > 1) {
                event.preventDefault();
            }
        };
        document.addEventListener('touchmove', handleZoom, {passive: false});
        return () => {
            document.removeEventListener('touchmove', handleZoom);
        };
    }, []);


    useEffect(() => {
        // Controlla il parametro dell'URL per impostare il tema
        const urlParams = new URLSearchParams(location.search);
        const themeParam = urlParams.get('theme');

        if (themeParam === 'dark') {
            setTheme('dark');
        } else {
            setTheme('light');
        }
    }, [location.search]);

    return (
        <>
            <Provider store={store}>

                {loading === false ? (
                    <div className={`App ${theme === 'dark' ? 'dark-theme' : ''}`}>
                        {/*<Header />*/}
                        <Routes>
                            <Route path="/Speedtest/" element={<Home/>}/>
                            <Route path="/" element={<Home/>}/>
                        </Routes>
                    </div>
                ) : (
                    <LoadingScreen/>
                )}
            </Provider>

        </>
    );
}

export default App;
