import React from 'react';
import { Card } from 'react-bootstrap';
import { MdArrowDownward, MdArrowUpward, MdLockClock, MdOutlineStorage, MdOutlineSwapVert } from 'react-icons/md';
import { TbTilde } from 'react-icons/tb';
import speedTestStyle from '../../styles/speedTestStyle.module.css';

const ValuesBox = (props) => {
    return (
        <Card className={`${speedTestStyle.boxValuesCard}`}>
            <Card.Body>
                <div className={`d-flex align-items-center justify-content-between ${speedTestStyle.boxValuesSection}`}>
                    {props.leftValue && (
                        <div className={`d-flex align-items-center ${speedTestStyle.boxValuesText}`}>
                            <MdArrowDownward className="mr-2" />
                            <Card.Text>{props.leftValue}mbps</Card.Text>
                        </div>
                    )}
                    {props.rightValue && (
                        <div className={`d-flex align-items-center ${speedTestStyle.boxValuesText}`}>
                            <MdArrowUpward className="ml-2" />
                            <Card.Text>{props.rightValue}mbps</Card.Text>
                        </div>
                    )}
                </div>
                <div className={`d-flex align-items-center justify-content-between ${speedTestStyle.boxValuesSection}`}>
                    {props.jitter && (
                        <div className={`d-flex align-items-center ${speedTestStyle.boxValuesText}`}>
                            <TbTilde />
                            <Card.Text>{props.jitter}</Card.Text>
                        </div>
                    )}
                    {props.latency && (
                        <div className={`d-flex align-items-center ${speedTestStyle.boxValuesText}`}>
                            <MdOutlineSwapVert />
                            <Card.Text>{props.latency}</Card.Text>
                        </div>
                    )}
                </div>


                {props.location && (
                    <div className={`d-flex align-items-center  justify-content-center ${speedTestStyle.boxValuesSection}`}>
                        <div className={`d-flex align-items-center ${speedTestStyle.boxValuesText}`}>

                            <MdOutlineStorage />
                            <Card.Text>{props.location}</Card.Text>
                        </div>
                    </div>

                )}

                {props.timestamp && (
                    <div className={`d-flex align-items-center justify-content-center ${speedTestStyle.boxValuesText}`}>
                        <MdLockClock />
                        <Card.Text>{props.timestamp}</Card.Text>
                    </div>
                )}
            </Card.Body>
        </Card>
    );
};

export default ValuesBox;
