import ndt7 from "../services/ndt7/ndt7";
import userGet from "../services/userGet";
import Button from '../components/Button';

import testButton from '../styles/testButtonStyle.module.css';
import {MdNetworkCheck, MdStopCircle} from 'react-icons/md'
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {DOWNLOAD_WORKER_URL} from "../constant/downloadWorkerUrl";
import {UPLOAD_WORKER_URL} from "../constant/uploadWorkerUrl";

var downloadWorkerUrl = URL.createObjectURL(
    new Blob([DOWNLOAD_WORKER_URL], {type: 'text/javascript'})
);

var uploadWorkerUrl = URL.createObjectURL(
    new Blob([UPLOAD_WORKER_URL], {type: 'text/javascript'})
);


//SpeedTest variables
let location = [];

let latency = '';
let jitter = '';

let MeanClientMbpsDownload = 0;
let MeanClientMbpsUpload = 0;
let downloadData = {};
let uploadData = {};

let ipClient = '';
let asn = '';//ASN = autonomous system number

let isDownloading = false;
let isUploading = false;
let isProcessFinish = undefined;
let isFirstTime = true;
//////////////


const SpeedTest = (props) => {
    const serverSelectedSelector = useSelector(state => state.serverSelected.value)
    const [isFirstStart, setIsFirstStart] = useState(true)
    const [hideButton, setHideButton] = useState(false)

    useEffect(() => {
        //START WHEN APP IS OPEN AND PROCESS FINISH
        if (isFirstStart || isProcessFinish) {
            downloadStream(isFirstStart, isProcessFinish)
            setIsFirstStart(false)


        }

    }, [isProcessFinish])
    const downloadStream = (firstStart, processFinished) => {
        isProcessFinish = undefined;
        isUploading = false;
        passUploadState(isUploading);
        passProcessFinished(isProcessFinish);


        ndt7.test(
            {
                userAcceptedDataPolicy: true,
                downloadworkerfile: downloadWorkerUrl,
                uploadworkerfile: uploadWorkerUrl,
                serverChosenByUser: serverSelectedSelector ? serverSelectedSelector : null,
                isFirstTime: firstStart,
                processFinished: processFinished

            },
            {

                serverChosen: function (server) {
                    let machine = server.machine.substring(0, server.machine.indexOf('.'));

                    location = [server.location.city, server.location.country, machine];


                    console.log('Testing to:', {
                        machine: server.machine,
                        locations: server.location,
                    });
                    passLocation(location);
                    printLocation(location);
                },

                handleServerList: function (serverList) {
                    props.handleServerList(serverList)
                },

                downloadMeasurement: function (data) {
                    isProcessFinish = false;
                    passProcessFinished(isProcessFinish);

                    isDownloading = true;
                    if (data.Source === 'client') {
                        downloadData = data.Data.MeanClientMbps;

                        passDownloadData(downloadData);
                        passDownloadState(isDownloading);

                    }
                    if (data.Source === 'server') {

                        if (isFirstTime) {
                            ipClient = data.Data.ConnectionInfo.Client;
                            ipClient = ipClient.substring(0, ipClient.indexOf(':'))

                            if (ipClient.length > 0) {
                                getIp();// Also call printIp(ipClient, asn);

                            }
                            isFirstTime = false;
                        }

                        latency = data.Data.TCPInfo.RTT;
                        jitter = (data.Data.TCPInfo.RTTVar / 1000).toFixed(2);

                        printLatency((latency / 1000).toFixed(0))// from microS tu milliS
                    }
                },

                downloadComplete: function (data) {
                    MeanClientMbpsDownload = data.LastClientMeasurement.MeanClientMbps;
                    printDownloadData();
                    props.onDownloadComplete(data.LastClientMeasurement.MeanClientMbps.toFixed(2))
                },

                uploadMeasurement: function (data) {
                    isUploading = true;
                    if (data.Source === 'server') {
                        uploadData = (data.Data.TCPInfo.BytesReceived / data.Data.TCPInfo.ElapsedTime * 8);
                        passUploadData(uploadData);
                        passUploadState(isUploading);
                    }
                },

                uploadComplete: function (data) {
                    MeanClientMbpsUpload = data.LastClientMeasurement.MeanClientMbps;
                    props.onUploadComplete(data.LastClientMeasurement.MeanClientMbps.toFixed(2))
                    setHideButton(false)
                    props.onProcessStart(false)
                },

                error: function (err) {
                    debugger
                    console.log('testError', err?.message ? err.message : err);
                    setHideButton(false)
                    props.onProcessStart(false)
                    if(!err.message.includes("TypeError")){

                        ndt7.stopWorker().then(res =>
                        {
                            downloadStream(true, true)
                            props.onServerRefresh()

                        }                    )
                    }


                },
            },
        ).then((exitcode) => {
            console.log('testCompletedWithCode', exitcode);
            if (exitcode === 0) {
                isFirstTime = true;
                isProcessFinish = true;

                passUploadState(isUploading);
                printUploadData();
                passProcessFinished(isProcessFinish);
            }
        });

    }

    /***********Props Drill Function ********/

    function passDownloadData(data) {
        props.handleDownloadData(data);
    }

    function passUploadData(data) {
        props.handleUploadData(data);
    }

    function passDownloadState(isDownloading) {
        props.isDownloadingFunction(isDownloading);
    }

    function passUploadState(isUploading) {
        props.isUploadingFunction(isUploading);
    }

    function passProcessFinished(isProcessFinish) {
        props.processFinished(isProcessFinish)

    }

    /*************************************************/


    /**************************Print Functions*********************************** */
    function passLocation(loc) {
        props.handleServerInfo(loc);
    }

    function printLocation(location) {
        document.getElementById('locationText').textContent = ' ' + location[0] + ',' + location[1] + ` (${location[2]})`;
        props.handleLocation(' ' + location[0] + ',' + location[1] + ` (${location[2]})`)
    }

    function printIp(ipClient) {
        document.getElementById('ipText').textContent = ' ' + ipClient + '   (' + asn + ')';
    }

    const getIp = async () => {
        const tmpIp = await userGet(ipClient);
        if(tmpIp?.data?.as_description){
            asn = tmpIp?.data?.as_description;

        }

        printIp(ipClient, asn);
    }

    function printLatency(latency) {
        document.getElementById('latencyText').textContent = ' ' + latency + ' ms';
        document.getElementById('jitterText').textContent = ' ' + jitter + ' ms';
        props.handleLatency(latency + 'ms')
        props.handleJitter(jitter + 'ms')

    }

    function printDownloadData() {
        document.getElementById('downloadText').textContent = ' ' + MeanClientMbpsDownload.toFixed(2) + ' Mbps';
    }

    function printUploadData() {
        document.getElementById('uploadText').textContent = ' ' + MeanClientMbpsUpload.toFixed(2) + ' Mbps';
    }


    if (isProcessFinish !== false && hideButton === false) { //If we're measuring , Button won't appear
        return (
            <Button onClick={() => {
                setHideButton(true)
                downloadStream(isFirstStart)
                props.onProcessStart(true)
            }} className={testButton.button} hidden={false} icon={<MdNetworkCheck/>}
                    title={'Press to start measurement'}></Button>

        )
    } else {
        return <Button onClick={() => {
            ndt7.stopWorker()
            props.resetInformationField()
            isProcessFinish=true
            setHideButton(false)
            props.onProcessStart(false)



        }} className={testButton.button} icon={<MdStopCircle/>}
                       title={'Press to start measurement'}></Button>
    }


}

export default SpeedTest;
