import { configureStore } from '@reduxjs/toolkit';

import serverSelectedSlice from './reducers/serverSelectedSlice';


export const store = configureStore({
  reducer: {
    serverSelected: serverSelectedSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
