import axios from "axios";

const baseURL = "https://masterofwireless.com/";
const userURL ="iptoasn/index.php?ip=";

const userGet = async (ip) => {
    try {
        const response = await axios.get(baseURL+userURL+ip);
        return response;
    } catch(err) {
        console.log(err);
    }
  
}

export default userGet;