import '../styles/loadingPage.css'

const LoadingScreen = () => {

    return(
        <div className="loader-container">
      	  <div className="spinner"></div>
        </div>

    )

}

export default LoadingScreen;